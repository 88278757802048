// Bootstrap Button Variant

.button-variant(@color; @background; @border) {
	color: @color;
	background-color: @background;
	border-color: @border;

	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle& {
		color: @color;
		background-color: darken(@background, 10%);
        border-color: darken(@border, 12%);
	}
	&:active,
	&.active,
	.open .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: @background;
			border-color: @border;
		}
	}

	.badge {
		color: @background;
		background-color: @color;
	}
}

// Background Features

.background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

// Font Selections

.serif-font() {
	font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.body-font() {
	font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.cn-font() {
	font-family: "Open Sans",Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Noto Sans CJK SC,Droid Sans Fallback,Microsoft YaHei,sans-serif;;
}

.heading-font() {
	font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
}
