.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #fed136;
  position: fixed;
  z-index: 12000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #fed136, 0 0 5px #fed136;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 12000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #fed136;
  border-left-color: #fed136;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.preloader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 20000;
}
.preloader .item {
  position: absolute;
  width: 220px;
  height: 50px;
  left: 50%;
  top: 50%;
  margin-left: -110px;
  margin-top: -65px;
}
.preloader .item strong {
  margin-top: 20px;
  font-size: 18px;
}
.preloader strong {
  display: block;
  font-family: Open Sans Condensed;
  text-transform: uppercase;
  color: #fed136;
  font-size: 18px;
  text-align: center;
  position: relative;
  margin-top: 180px;
  -webkit-animation: fade 2s infinite;
  -moz-animation: fade 2s infinite;
  font-weight: bold;
  z-index: 22;
}
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  background-color: #fed136;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.preloader .Cube {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 45px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-indent: 34px;
  font-weight: bold;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 0.5s 0.1s;
  transition: transform 0.5s 0.1s;
  perspective: 9999px;
  margin: -40px 0 0 -40px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  margin-left: -37px;
  top: 65px;
  opacity: 1;
  z-index: 21;
}
.preloader-cont {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 430px;
  margin-left: -215px;
  margin-top: -100px;
  text-align: center;
}
.preloader .Cube.panelLoad {
  z-index: 11;
  top: 50%;
  margin-top: -60px;
  -webkit-animation: panel 3.2s infinite forwards;
  animation: panel 3.2s infinite forwards;
  scale: 0.8 0.8;
}
.preloader .Cube.panelLoad .cube-face {
  color: #fff;
  background: #fed136;
  overflow: hidden;
}
.preloader .Cube .cube-face {
  width: inherit;
  height: inherit;
  position: absolute;
  background: #fff;
  box-shadow: inset 0 0 0 1px #fff, 0 0 1px 1px #fff;
  opacity: 1;
}
.preloader .Cube .cube-face span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: -18px;
  text-align: left;
}
.preloader .Cube .cube-face-front {
  transform: translate3d(0, 0, 40px);
  -webkit-transform: translate3d(0, 0, 40px);
}
.preloader .Cube .cube-face-back {
  -webkit-transform: rotateY(180deg) translate3d(0, 0, 40px);
  transform: rotateY(180deg) translate3d(0, 0, 40px);
}
.preloader .Cube .cube-face-left {
  -webkit-transform: rotateY(-90deg) translate3d(0, 0, 40px);
  transform: rotateY(-90deg) translate3d(0, 0, 40px);
}
.preloader .Cube .cube-face-right {
  -webkit-transform: rotateY(90deg) translate3d(0, 0, 40px);
  transform: rotateY(90deg) translate3d(0, 0, 40px);
}
.preloader .Cube .cube-face-top {
  -webkit-transform: rotateX(90deg) translate3d(0, 0, 40px);
  transform: rotateX(90deg) translate3d(0, 0, 40px);
}
.preloader .Cube .cube-face-bottom {
  -webkit-transform: rotateX(-90deg) translate3d(0, 0, 40px);
  transform: rotateX(-90deg) translate3d(0, 0, 40px);
}
@-webkit-keyframes fade {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes panel {
  0% {
    -webkit-transform: rotateY(0deg) rotateZ(0deg);
  }
  20% {
    -webkit-transform: rotateY(90deg) rotateZ(0deg);
  }
  40% {
    -webkit-transform: rotateX(45deg) rotateZ(45deg);
  }
  60% {
    -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
  }
  80% {
    -webkit-transform: rotateX(310deg) rotateZ(230deg);
  }
  100% {
    -webkit-transform: rotateX(360deg) rotateZ(360deg);
  }
}
@keyframes panel {
  0% {
    -webkit-transform: rotateY(0deg) rotateZ(0deg);
    transform: rotateY(0deg) rotateZ(0deg);
  }
  20% {
    -webkit-transform: rotateY(90deg) rotateZ(0deg);
    transform: rotateY(90deg) rotateZ(0deg);
  }
  40% {
    -webkit-transform: rotateX(45deg) rotateZ(45deg);
    transform: rotateX(45deg) rotateZ(45deg);
  }
  60% {
    -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
    transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
  }
  80% {
    -webkit-transform: rotateX(310deg) rotateZ(230deg);
    transform: rotateX(310deg) rotateZ(230deg);
  }
  100% {
    -webkit-transform: rotateX(360deg) rotateZ(360deg);
    transform: rotateX(360deg) rotateZ(360deg);
  }
}
